import React from 'react'
import Share from '../../../RadioUI/Share'
import Text from '../../../RadioUI/Text'
import clientConfig from '../../../clientConfig'
import { useParams } from 'react-router-dom'
import { useContentType } from '../hooks/seriesHooks'
import { trackSnowplowEvent, spCategory } from '../../../clientMonitoring'

const createSeriesShareUrl = (contentType, seriesId) => {
  const base = contentType === 'podcast' ? '/podkast' : '/serie'
  return `https://${clientConfig.HOST}${base}/${seriesId}`
}

const SeriesShare = () => {
  const params = useParams()
  const contentType = useContentType()
  return (
    <Share
      mr="2"
      url={createSeriesShareUrl(contentType, params.seriesId)}
      onShare={() => trackSnowplowEvent(spCategory.SeriesPage, 'ShareButtonTapped')}
      aria-label="Del serie"
      modalHeader="Del serie"
      data-test="shareSeries"
    >
      <Text px="8px" display={['none', 'inline']}>
        Del
      </Text>
    </Share>
  )
}

export default SeriesShare
