import React from 'react'
import PropTypes from 'prop-types'
import Box from '../../../RadioUI/Box'
import Image from '../../../RadioUI/Image'
import LoadingPlaceholder from '../../../RadioUI/LoadingPlaceholder/LoadingPlaceholder'

const sizes = {
  base: '114px',
  sm: '114px',
  md: '216px',
  lg: '216px',
  xl: '260px'
}

const TitleImage = ({ src, srcSet, isLoading, ...props }) => {
  if (isLoading) {
    return <LoadingPlaceholder h={sizes} minW={sizes} {...props} />
  }

  return (
    <Box className="SeriesImageContainer" h={sizes} minW={sizes} {...props}>
      {src && (
        <Image
          maxH={sizes}
          width="auto"
          height="auto"
          useIsInView={false}
          borderRadius="6px"
          src={src}
          srcSet={srcSet}
        />
      )}
    </Box>
  )
}

TitleImage.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string,
  isLoading: PropTypes.bool
}

export default TitleImage
