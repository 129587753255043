import React from 'react'
import { useContentType, usePreferredSeriesHrefTemplate, useSeriesVariantHref } from '../hooks/seriesHooks'
import { Link, useParams } from 'react-router-dom'
import Icon from '../../../RadioUI/Icon'
import Text from '../../../RadioUI/Text'
import CustomButton from '../../../RadioUI/CustomButton'

/**
 * Creates a new href string based on `_links.["podkast"|"serie"].href` and the current contentType and sesason.
 * @param {string} seriesVariantHref
 * @param {string} contentType
 * @param {string} seasonId
 * @returns {string} Href
 */

const createVariantToLink = (seriesVariantHref, contentType, seasonId) => {
  const [variantId] = seriesVariantHref.split('/').reverse()
  const contentTypePart = contentType === 'podcast' ? '/serie' : '/podkast'
  const seriesPart = `/${variantId}`
  const seasonPart = seasonId ? `/sesong/${seasonId}` : ``
  return `${contentTypePart}${seriesPart}${seasonPart}`
}

/**
 * This is a button that links to either the podcast- or radioversion of a series.
 * @returns {JSX.Element|null} Component?
 */
const SwitchContentTypeButton = () => {
  const preferredSeriesPath = usePreferredSeriesHrefTemplate()
  const seriesVariantHref = useSeriesVariantHref()
  const contentType = useContentType()
  const params = useParams()
  const buttonText = contentType === 'podcast' ? 'Gå til radioversjon' : 'Gå til podkasten'
  const iconId = contentType === 'podcast' ? 'nrk-hardware-radio' : 'nrk-hardware-headphones'

  if (!seriesVariantHref && !preferredSeriesPath) return null

  const to = seriesVariantHref
    ? createVariantToLink(seriesVariantHref, contentType, params.seasonId)
    : preferredSeriesPath
  return (
    <CustomButton
      aria-label={buttonText}
      type={null}
      as={Link}
      mr="2"
      to={to}
      px={{ base: '8px', sm: '10px' }}
      d="flex"
      alignItems="center"
    >
      <Icon pl="4px" iconId={iconId} />
      <Text px="8px">{buttonText}</Text>
    </CustomButton>
  )
}

export default SwitchContentTypeButton
