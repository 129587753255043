import React from 'react'
import { useSeriesId, useContentType } from '../hooks/seriesHooks'
import { css } from '@emotion/react'
import breakpoints from '../../../RadioUI/theme/breakpoints'
import { useIsLoggedIn } from '../../../components/Auth/AuthContext'
import NotLoggedInDialog from '../../../components/Favourite/NotLoggedInDialog'
import FavouriteButton from '../../../components/Favourite/FavouriteButton'
import { useFavourite } from '../../../components/hooks/useFavourite'
import { trackSnowplowEvent, spCategory } from '../../../clientMonitoring'

const style = css`
  .FavouriteButton__text {
    display: none;

    @media (min-width: ${breakpoints.sm}) {
      display: inline-block;
    }
  }
`

const SeriesFavourite = props => {
  const seriesId = useSeriesId()
  const contentType = useContentType()
  const isLoggedIn = useIsLoggedIn()

  const favouriteContentType = contentType === 'onDemand' ? 'series' : contentType

  const { isLoading, isFavourite, toggleFavourite } = useFavourite({ contentId: seriesId, favouriteContentType })

  if (!seriesId || !favouriteContentType) return null

  if (!isLoggedIn) {
    return <NotLoggedInDialog mr="2" {...props} css={style} />
  }

  return (
    <FavouriteButton
      mr="2"
      {...props}
      loading={isLoading}
      isFavourite={isFavourite}
      onClick={() => {
        trackSnowplowEvent(spCategory.SeriesPage, isFavourite ? 'UnFollowButtonTapped' : 'FollowButtonTapped')
        toggleFavourite()
      }}
      css={style}
    />
  )
}

SeriesFavourite.propTypes = {}

export default SeriesFavourite
