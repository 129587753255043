import React from 'react'
import Button from '../../../RadioUI/Button'
import { Link, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { trackSnowplowEvent, spCategory } from '../../../clientMonitoring'
import VideoShortsIcon from './VideoShortsIcon'

const ExtraMaterialLink = props => {
  const params = useParams()
  const toPath = `/${params.contentType}/${params.seriesId}/ekstramateriale`
  const location = useLocation()
  const match = useRouteMatch(`/:contentType(podkast|serie)/:seriesId/ekstramateriale/:extraMaterialId?`)
  const active = !!match?.isExact

  return (
    <Button
      as={Link}
      onClick={() => trackSnowplowEvent(spCategory.SeriesPage, 'ExtramaterialButtonTapped')}
      active={active}
      type={null}
      aria-label="Videoklipp"
      to={{ pathname: toPath, prevPathname: location.pathname }}
      {...props}
    >
      <VideoShortsIcon />
    </Button>
  )
}

ExtraMaterialLink.propTypes = {}

export default ExtraMaterialLink
