import React from 'react'
import Box from '../../../RadioUI/Box'

const VideoShortsIcon = () => {
  return (
    <Box>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 17V3L7 3V1H21L21 17H19Z" fill="white" />
        <path d="M10.6667 10.6666H8L9.83333 14L8 17.3333H10.6667L12.5 14L10.6667 10.6666Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3 23H17L17 5L3 5V23ZM15 21L15 7H5L5 21H15Z" fill="white" />
      </svg>
    </Box>
  )
}

export default VideoShortsIcon
