import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import CoreDialog from '@nrk/core-dialog/jsx'
import Portal from '../../RadioUI/Portal'
import FavouriteButton from './FavouriteButton'
import Box from '../../RadioUI/Box'
import modalStyle from '../../RadioUI/Portal/modalStyle'
import IconButton from '../../RadioUI/IconButton'
import Button from '../../RadioUI/Button'
import { useColors } from '../../RadioUI/theme/Color'
import Text from '../../RadioUI/Text'
import { useAuthContext } from '../Auth/AuthContext'

const MaybePortal = ({ addToRoot, children }) => {
  if (addToRoot) {
    return <Portal>{children}</Portal>
  }
  return <>{children}</>
}

MaybePortal.propTypes = {
  addToRoot: PropTypes.bool,
  children: PropTypes.node
}

const NotLoggedInDialog = props => {
  const [open, setOpen] = useState(false)
  const coreDialogDomRef = React.createRef()
  const { contentId, location, addToRoot = true, ...restProps } = props
  const colors = useColors()
  const { login } = useAuthContext()

  const toggleDialog = event => {
    event.preventDefault()
    setOpen(!open)
  }

  const handleToggle = event => {
    event.preventDefault()
    setOpen(!event.target.hidden)
  }

  return (
    <>
      <FavouriteButton onClick={toggleDialog} {...restProps} />
      <MaybePortal addToRoot={addToRoot}>
        <CoreDialog
          hidden={!open}
          onDialogToggle={handleToggle}
          aria-label="Logg på dialog"
          forwardRef={coreDialogDomRef}
          css={modalStyle}
        >
          <Box
            bg={colors.personal.light}
            color={colors.personal.mediumLight10}
            rounded="md"
            w="313px"
            p="1.5rem"
            position="relative"
          >
            <Text mb="16px" size="xl" weight="bold" tabIndex="-1">
              Logg på
            </Text>
            <Text mb="16px" size="md">
              Du må logge på for å følge dine favorittprogrammer.
            </Text>
            <Button
              variantColor="personal"
              variant="login"
              rightIcon="nrk-open-in-new"
              onClick={() => {
                login()
              }}
              type={null}
              data-test="loginPlease"
              data-ga-category="radio-login"
              data-ga-action="login-start"
              data-ga-label={location.pathname}
            >
              Logg på
            </Button>

            <IconButton
              iconId="nrk-close"
              onClick={toggleDialog}
              aria-label="Lukk innloggingsbeskjeden"
              pos="absolute"
              top="12px"
              right="12px"
              fontSize="sm"
              w="24px"
              h="24px"
              variantColor="personal"
              variant="close"
            />
          </Box>
        </CoreDialog>
      </MaybePortal>
    </>
  )
}

NotLoggedInDialog.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  contentId: PropTypes.string,
  addToRoot: PropTypes.bool
}

export default withRouter(NotLoggedInDialog)
